import { useHistory } from "react-router-dom";
const Card = ({ title, img, url, type }) => {
  const history = useHistory();
  const clickHandler = () => history.push(url);
  return (
    <div className="column is-one-quarter-fullhd is-one-third-widescreen is-one-third-desktop is-two-fifths-tablet p-1 m-3">
      <div
        onClick={clickHandler}
        style={{ borderRadius: "15px", cursor: "pointer" }}
        className="mx-auto card"
      >
        <div className="card-image">
          <figure className="image">
            <img src={img} alt="" />
          </figure>
        </div>
        <div className="card-content">
          <h3
            className="has-text-centered is-size-4-mobile is-size-6-tablet is-size-5-desktop"
            style={{ fontFamily: "Lato", color: "#164D6C" }}
          >
            {title}
          </h3>
          <p
            className="has-text-centered is-size-4-mobile is-size-6-tablet is-size-5-desktop mb-4"
            style={{ fontFamily: "Lato" }}
          >
            {type}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Card;
