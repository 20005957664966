import { Auth } from "aws-amplify";

import { useState, useEffect } from "react";
import UserContext from "./UserContext";

const ContextProvider = (props) => {
  const [user, setUser] = useState();
  const [authState, setAuthState] = useState();
  const [userGroup, setUserGroup] = useState([]);
  const [apiRacelabDwInternal, setApiRacelabDwInternal] = useState(-1);
  const [apiRacelabPush, setApiRacelabPush] = useState(-1);
  const [apiRacelabExoticsPush, setApiRacelabExoticsPush] = useState(-1);
  const [apiRacelabPull, setApiRacelabPull] = useState(-1);
  const [apiOddsengineBetstream, setApiOddsengineBetstream] = useState(-1);
  const [apiOddsengineBetstreamInternal, setApiOddsengineBetstreamInternal] = useState(-1);

  //Signout Handler
  const signoutUser = async () => {
    await Auth.signOut();
    setUser(null);
    setAuthState(null);
  };

  //This useEffect takes the array of categories that Cognito provides and searches the indexOf each category,
  //this allows us to conditionally render elements if the state is > -1, when adding new categories please add new
  //useState above, check for the indexOf in the useEffect below and pass it though the context
  useEffect(() => {
    if (userGroup === undefined) {
      return;
    }

    setApiRacelabDwInternal(userGroup.indexOf("API-racelab-dw-internal"));
    setApiRacelabPush(userGroup.indexOf("API-racelab-push"));
    setApiRacelabExoticsPush(userGroup.indexOf("API-racelab-exotics-push"));
    setApiRacelabPull(userGroup.indexOf("API-racelab-pull"));
    setApiOddsengineBetstream(userGroup.indexOf("API-oddsengine-betstream"));
    setApiOddsengineBetstreamInternal(userGroup.indexOf("API-oddsengine-betstream-internal"));
  }, [userGroup]);
  return (
    <UserContext.Provider
      value={{
        signoutUser,
        setUserGroup,
        setUser,
        setAuthState,
        user,
        authState,
        userGroup,
        apiRacelabDwInternal,
        apiRacelabPush,
        apiRacelabExoticsPush,
        apiRacelabPull,
        apiOddsengineBetstream,
        apiOddsengineBetstreamInternal,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
export default ContextProvider;
