import Card from "./Card";

import { useContext } from "react";
import UserContext from "../store/UserContext";
import SignOut from "./SignOut";

import racelab from "../assets/ProForm3.png";
import oddsEngineImg from "../assets/OddsEngine3.png";
import racelabLogoImg from "../assets/racelab-logo-white-side.svg";
// w 526 h 507
const HomePage = () => {
  const {
    userGroup,
    apiRacelabDwInternal,
    apiRacelabPush,
    apiRacelabExoticsPush,
    apiRacelabPull,
    apiOddsengineBetstream,
    apiOddsengineBetstreamInternal,
  } = useContext(UserContext);
  return (
    <div
      style={{ backgroundColor: "#041031", height: "100vh" }}
      className="body"
    >
      <SignOut />
      <div className="">
        <div className="mb-3">
          <div className="has-text-centered main-logo-wrapper">
            <img
              src={racelabLogoImg}
              alt="main racelab logo"
              className="main-logo"
            />
          </div>
          {userGroup === undefined && (
            <h3 className="mt-5 is-size-3 has-text-centered has-text-white">
              You currently don't have access to any content, please contact
              support@racelabglobal.com
            </h3>
          )}
        </div>
        <div className="columns is-centered is-multiline p-5">
          {apiRacelabPull > -1 && (
            <Card
              title="RACELAB"
              img={racelab}
              url="/racelab-pull"
              type="API"
            />
          )}
          {apiOddsengineBetstream > -1 && (
            <Card
              title="BETSTREAM"
              img={oddsEngineImg}
              url="/betstream"
              type="API"
            />
          )}
          {apiOddsengineBetstreamInternal > -1 && (
            <Card
              title="ODDS ENGINE Plus"
              img={oddsEngineImg}
              url="/betstream-internal"
              type="Internal API"
            />
          )}
          {apiRacelabPush > -1 && (
            <Card
              title="RACELAB"
              img={oddsEngineImg}
              url="/racelab-push"
              type="Push API"
            />
          )}
          {apiRacelabExoticsPush > -1 && (
            <Card
              title="RACELAB"
              img={oddsEngineImg}
              url="/racelab-exotics-push"
              type="Exotics Push API"
            />
          )}
          {apiRacelabDwInternal > -1 && (
            <Card
              title="RACELAB DW"
              img={oddsEngineImg}
              url="/racelab-internal"
              type="Internal API"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
