import { useContext, useEffect } from "react";
import Amplify from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import awsconfig from "../aws-exports";
import UserContext from "../store/UserContext";
import racelabLogoImg from "../assets/racelab-logo-white-side.svg";

Amplify.configure(awsconfig);
const AuthStateApp = () => {
  const { setUser, setAuthState, setUserGroup } = useContext(UserContext);
  //The useEffect section updates state when someone signs in/comes back to the app
  //and lets us use the users data for conditional rendering
  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setUser(authData);
      setAuthState(nextAuthState);
      //The if statement fixes a bug where app crashes when not signed in
      if (nextAuthState === "signedin") {
        setUserGroup(
          authData.signInUserSession.idToken.payload["cognito:groups"]
        );
      }
    });
  }, []);

  return (
    <div style={{ backgroundColor: "#041031" }}>
      <div className="has-text-centered main-logo-wrapper-signin">
        <img src={racelabLogoImg} alt="Racelab Logo" className="main-logo" />
      </div>
      <AmplifyAuthenticator style={{ backgroundColor: "#041031" }}>
        <AmplifySignUp
          slot="sign-up"
          formFields={[
            { type: "username" },
            { type: "password" },
            { type: "email" },
          ]}
        />
      </AmplifyAuthenticator>
    </div>
  );
};

export default AuthStateApp;
