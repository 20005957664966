import { RedocStandalone } from "redoc";
import SignOut from "./SignOut";

const RedocProvider = ({ content, group }) => {
  //This is a reusable Redoc component that takes in 2 conditions, content and group
  //Content lets us render the correct json file for the component
  //group is where we pass which group is allowed to see the content
  return (
    <div style={{ backgroundColor: "white" }}>
      <SignOut />
      {group > -1 ? (
        <RedocStandalone
          spec={content}
          options={{
            theme: {
              colors: {
                primary: {
                  main: "#164D6C",
                },
              },
              rightPanel: {
                backgroundColor: "#041031",
              },
              typography: {
                fontFamily: "Lato",
                headings: { fontFamily: "Lato" },
              },
              spacing: { sectionVertical: 10 },
              sidebar: {
                fontFamily: "Lato",
                backgroundColor: "#F3F3F3",
              },
              codeBlock: { backgroundColor: "#1F2631" },
            },
            hideDownloadButton: true,
            hideSchemaTitles: true,
            routingBasePath: "/betstream",
          }}
        />
      ) : (
        <h2 className="text-center pt-5">
          Oops, you do not have access to this content, please contact
          support@racelabglobal.com
        </h2>
      )}
    </div>
  );
};
export default RedocProvider;
