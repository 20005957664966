import { BrowserRouter, Route, Switch } from "react-router-dom";
import racelabBundledFile from "./racelab-bundled.json";
import oddsEngineBetsreamFile from "./betstream-bundled.json";
import oddsEngineBetstreamInternalFile from "./betstream-internal-bundled.json";
import DateWarehouseFile from "./datawarehouse-bundled.json";
import ExoticsPushFile from './exotics-bundled.json';
import DataWarehouseInternal from "./dw-internal-api-bundle.json";
import RedocProvider from "./components/RedocProvider";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { AuthState } from "@aws-amplify/ui-components";
import AuthStateApp from "./components/AuthStateApp";
import HomePage from "./components/HomePage";
import { useContext } from "react";

import UserContext from "./store/UserContext";
Amplify.configure(awsconfig);

const App = () => {
  const {
    user,
    authState,
    apiRacelabDwInternal,
    apiRacelabPush,
    apiRacelabExoticsPush,
    apiRacelabPull,
    apiOddsengineBetstream,
    apiOddsengineBetstreamInternal,
  } = useContext(UserContext);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          {authState === AuthState.SignedIn && user ? (
            <HomePage />
          ) : (
            <AuthStateApp />
          )}
        </Route>
        <Route path="/betstream">
          {authState === AuthState.SignedIn && user ? (
            <RedocProvider
              content={oddsEngineBetsreamFile}
              group={apiOddsengineBetstream}
            />
          ) : (
            <AuthStateApp />
          )}
        </Route>
        <Route path="/betstream-internal">
          {authState === AuthState.SignedIn && user ? (
            <RedocProvider
              content={oddsEngineBetstreamInternalFile}
              group={apiOddsengineBetstreamInternal}
            />
          ) : (
            <AuthStateApp />
          )}
        </Route>
        <Route path="/racelab-pull">
          {authState === AuthState.SignedIn && user ? (
            <RedocProvider
              content={racelabBundledFile}
              group={apiRacelabPull}
            />
          ) : (
            <AuthStateApp />
          )}
        </Route>
        <Route path="/racelab-push">
          {authState === AuthState.SignedIn && user ? (
            <RedocProvider 
              content={DateWarehouseFile} 
              group={apiRacelabPush} 
            />
          ) : (
            <AuthStateApp />
          )}
        </Route>
        <Route path="/racelab-exotics-push">
          {authState === AuthState.SignedIn && user ? (
            <RedocProvider 
              content={ExoticsPushFile} 
              group={apiRacelabExoticsPush} 
            />
          ) : (
            <AuthStateApp />
          )}
        </Route>
        <Route path="/racelab-internal">
          {authState === AuthState.SignedIn && user ? (
            <RedocProvider
              content={DataWarehouseInternal}
              group={apiRacelabDwInternal}
            />
          ) : (
            <AuthStateApp />
          )}
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
