import { Auth } from "aws-amplify";
import UserContext from "../store/UserContext";
import { useContext } from "react";
import logout from "../assets/logout_white_48dp.svg";
import RLG from "../assets/Racelab-White-Full-Logo.png";
import { Link } from "react-router-dom";

const SignOut = () => {
  const { setUser, setAuthState, setUserGroup } = useContext(UserContext);
  const signoutHandler = async () => {
    await Auth.signOut({ global: true });
    setUser(null);
    setAuthState(null);
    setUserGroup([]);
  };
  return (
    <div className="navbar is-dark">
      <div className="navbar-menu">
        <div className="navbar-start">
          <div style={{ cursor: "pointer" }} className="navbar-brand">
            <Link to="/" className="navbar-item">
              <img src={RLG} alt="racelab full white logo" width="30" />
            </Link>
          </div>
        </div>

        <div className="navbar-end">
          <img
            style={{ cursor: "pointer" }}
            src={logout}
            alt="sign out button"
            onClick={signoutHandler}
          />
        </div>
      </div>
    </div>
  );
};
export default SignOut;
